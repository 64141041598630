import React, { useState, useEffect } from 'react';
import { Link } from "gatsby";

// Style
import './style.scss';

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Header = ({headerColor, selectedComponent}) => {

  const [windowWidth, setWindowWidth] = useState(1281);
  const [navOpen, setNavOpen] = useState(false);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener('resize', updateSize);
  }, []);

  const updateSize = () => {
    setWindowWidth(window.innerWidth)
  }

  return (
    <header className={`headerContainer ${headerColor}`}>
      <div className="logo">
        <Link to="/#home">
          <svg className="logoImg" xmlns="http://www.w3.org/2000/svg" width="23.514" height="24" viewBox="0 0 23.514 24">
            <g id="Groupe_1" data-name="Groupe 1" transform="translate(0 0)">
              <path id="Tracé_7" data-name="Tracé 7" d="M16.842,27.48H27.33a12.046,12.046,0,0,0-.958-2.336H16.893a5.851,5.851,0,0,1-5.815-5.324A12.138,12.138,0,0,0,8.9,21.05a8.2,8.2,0,0,0,7.942,6.43Z" transform="translate(-4.289 -18.836)" fill="#fdfdfd"/>
              <path id="Tracé_8" data-name="Tracé 8" d="M24.738,22.081h5.528a12.23,12.23,0,0,0-2.716-2.342H24.768a2.623,2.623,0,0,1-2.485-1.788c-.364-.036-.733-.051-1.107-.051a12.405,12.405,0,0,0-1.307.072,4.98,4.98,0,0,0,4.868,4.109Z" transform="translate(-9.639 -17.9)" fill="#fdfdfd"/>
              <path id="Tracé_9" data-name="Tracé 9" d="M16.827,47.62a8.2,8.2,0,0,0-7.957,6.5,11.965,11.965,0,0,0,2.188,1.24,5.846,5.846,0,0,1,5.821-5.4H26.4a11.687,11.687,0,0,0,.933-2.342Z" transform="translate(-4.275 -32.341)" fill="#fdfdfd"/>
              <path id="Tracé_10" data-name="Tracé 10" d="M24.728,56.39a4.98,4.98,0,0,0-4.878,4.155,12.522,12.522,0,0,0,1.317.072,7.674,7.674,0,0,0,1.086-.051,2.644,2.644,0,0,1,2.505-1.814h2.854a11.785,11.785,0,0,0,2.675-2.342H24.728Z" transform="translate(-9.629 -36.617)" fill="#fdfdfd"/>
              <path id="Tracé_11" data-name="Tracé 11" d="M7.348,36.2H23.455c.036-.379.056-.763.056-1.158a8.273,8.273,0,0,0-.056-1.163H7.4A5.952,5.952,0,0,1,1.468,28.5,11.686,11.686,0,0,0,0,31.676,8.32,8.32,0,0,0,7.348,36.2Z" transform="translate(0 -23.05)" fill="#fdfdfd"/>
            </g>
          </svg>
          <svg className="logoTxt" xmlns="http://www.w3.org/2000/svg" width="71.321" height="24" viewBox="0 0 71.321 24">
            <g id="Groupe_2" data-name="Groupe 2" transform="translate(0)">
              <path id="Tracé_2" data-name="Tracé 2" d="M207.36,26.008h0v8.338h3.294V26.06a5.193,5.193,0,0,1,5.193-5.193v-3.3A8.5,8.5,0,0,0,207.36,26.008Z" transform="translate(-155.528 -11.037)" fill="#fdfdfd"/>
              <path id="Tracé_3" data-name="Tracé 3" d="M245.472,0A8.5,8.5,0,0,0,237,8.444h0V23.309h3.294V8.5a5.194,5.194,0,0,1,5.193-5.2Z" transform="translate(-174.166)" fill="#fdfdfd"/>
              <path id="Tracé_4" data-name="Tracé 4" d="M163.2,17.85a8.679,8.679,0,1,0,8.679,8.679h0A8.687,8.687,0,0,0,163.2,17.85Zm0,14.065a5.386,5.386,0,1,1,5.386-5.386A5.386,5.386,0,0,1,163.2,31.915Z" transform="translate(-122.297 -11.213)" fill="#fdfdfd"/>
              <path id="Tracé_5" data-name="Tracé 5" d="M112.715,31.946a5.386,5.386,0,1,1,4.477-8.375h3.661a8.672,8.672,0,1,0,.134,5.612h-3.572A5.378,5.378,0,0,1,112.715,31.946Z" transform="translate(-90.56 -11.244)" fill="#fdfdfd"/>
              <path id="Tracé_6" data-name="Tracé 6" d="M75.4,24.852c-.23-.108-.46-.208-.686-.308-1.554-.682-2.9-1.272-2.9-2.363a1.454,1.454,0,0,1,1.458-1.358A2.162,2.162,0,0,1,75.127,22.1l.093.2,2.849-1.524-.089-.178a4.933,4.933,0,0,0-4.536-2.9,4.87,4.87,0,0,0-4.9,4.822c0,2.3,1.372,3.227,3.168,4.191l2.967,1.587c.7.467,1.031.82,1.031,1.565a2.086,2.086,0,0,1-2.225,2.036,2.987,2.987,0,0,1-2.711-1.932l-.089-.167L67.72,31.139l.07.182a6.1,6.1,0,0,0,5.7,3.709,5.52,5.52,0,0,0,5.679-5.475C79.192,26.933,77.275,25.705,75.4,24.852Z" transform="translate(-67.72 -11.113)" fill="#fdfdfd"/>
              <path id="Tracé_1" data-name="Tracé 1" d="M252.517,17.56h1.521v3.3h-3.4V19.437a1.88,1.88,0,0,1,1.877-1.877Z" transform="translate(-182.747 -11.042)" fill="#fdfdfd"/>
            </g>
          </svg>
        </Link>
      </div>
      {windowWidth > 1280 && 
        <nav className="navigation">
          <div className={selectedComponent === 0 ? "navElement navElementSelected" : "navElement"}><Link to="/#features">Notre promesse</Link></div>
          <div className={selectedComponent === 1 ? "navElement navElementSelected" : "navElement"}><Link to="/#forWho">Pour qui ? </Link></div>
          <div className={selectedComponent === 2 ? "navElement navElementSelected" : "navElement"}><Link to='/#offer'>Nos Offres</Link></div>
          <div className={selectedComponent === 3 ? "navElement navElementSelected" : "navElement"}><Link to='/#faq'>FAQ</Link></div>
          <div className={selectedComponent === 4 ? "navElement navElementSelected" : "navElement"}><Link to='/#contact'>Contact</Link></div>
          {/* <div className={selectedComponent === 5 ? "navElement navElementSelected" : "navElement"}><Link to='/blog'>Blog</Link></div> */}
        </nav>
      }
      {windowWidth > 1280 &&
      <div className="loginContainer">
        <div className="connectTo">Connect to</div>
        <div className="loginDesktop style1">
          <a href='https://app.scorf.io/'>PRO</a>
        </div>
        <div className="loginDesktop style2">
          <a href='https://spot.scorf.io/'>SPOT</a>
        </div>
      </div>
      }
      {windowWidth <= 1280 &&
        <nav className={navOpen ? "navMobile": "navMobile navMobileHidden"}>
          <div className="links">
            <div className={selectedComponent === 0 ? "navElement navElementSelected" : "navElement"} onClick={() => setNavOpen(false)}><Link to="/#features">Notre promesse</Link></div>
            <div className={selectedComponent === 1 ? "navElement navElementSelected" : "navElement"} onClick={() => setNavOpen(false)} ><Link to="/#forWho">Pour qui ? </Link></div>
            <div className={selectedComponent === 2 ? "navElement navElementSelected" : "navElement"} onClick={() => setNavOpen(false)}><Link to='/#offer'>Nos Offres</Link></div>
            <div className={selectedComponent === 3 ? "navElement navElementSelected" : "navElement"} onClick={() => setNavOpen(false)}><Link to='/#faq'>FAQ</Link></div>
            <div className={selectedComponent === 4 ? "navElement navElementSelected" : "navElement"} onClick={() => setNavOpen(false)}><Link to='/#contact'>Contact</Link></div>
            {/* <div className={selectedComponent === 5 ? "navElement navElementSelected" : "navElement"} onClick={() => setNavOpen(false)}><Link to='/#contact'>Blog</Link></div> */}
          </div>
        </nav>
      }
      {windowWidth <= 1280 &&
        <div className={navOpen ? "burger burgerOpen" : "burger"} onClick={() => setNavOpen(!navOpen)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="32.001" height="24" viewBox="0 0 32.001 24">
            <g id="bx-menu-alt-right" transform="translate(-5.333 -8)">
              <path id="Tracé_24" data-name="Tracé 24" d="M5.333,8h32v4h-32Zm8,10h24v4h-24Zm10,10h14v4h-14Z" transform="translate(0 0)" fill="#fdfdfd"/>
            </g>
          </svg>
        </div>
      }
    </header>
  );
};

export default Header;
