import React from 'react';

import { Link } from "gatsby";

// Style
import './style.scss';

// Images 
import logo from '../../images/logo.png'
import linkedin from '../../images/linkedin.png'

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Footer = () => {

  return (
    <footer className="footerContainer">
      <div className="footer">
        <div className="informations">
          <img className={'logo'} src={logo} alt="logo"/>
          <div className="companyInfo">
            <div className="infoContainer">
              <div className="title">Adresse</div>
              <p>80 rue de Taitbout</p>
              <p>75009 Paris</p>
              <p>France</p>
            </div>
            <div className="contact">
              <div className="infoContainer">
                <div className="title">Mail</div>
                <p>contact@scorf.io</p>
              </div>
            </div>
          </div>
                    
        </div>
        <div className="moreInfo">
          <div className="languages">
            <a href="https://fr.linkedin.com/company/scorf" target="_blank">
              <img src={linkedin} alt="linkedin"/>
            </a>
            <div className="legals">
              <Link to='/cgu'><p>Conditions d'utilisations</p></Link>
              <div className="ellipse"></div>
              <Link to='/policy'><p>Politique de confidentialité</p></Link>
              <div className="ellipse"></div>
              <Link to='/mentions'><p>Mentions légales</p></Link>
            </div>
          </div>
          <div className="links">
            © Copyright 2021 Scorf. All rights reserved.
          </div>
        </div>      
      </div>      
    </footer>
  );
};

export default Footer;